import React from "react";

interface IProps {
  color?: string;
  className?: string;
  height?: number | string;
  width?: number | string;
}

function GreatUX({ color, className, height, width }: IProps): JSX.Element {
  const colorToUse = color || "#000";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 1345.09 969.65"
    >
      <path
        d="M17.49,2.74h951c9.66,0,17.49,7.07,17.49,15.8V877.82c0,8.73-7.83,15.8-17.49,15.8h-951C7.83,893.62,0,886.55,0,877.82V18.54C0,9.81,7.83,2.74,17.49,2.74Z"
        transform="translate(0 -0.56)"
        fill={colorToUse}
      />
      <path
        d="M21.35,3.06H965.07c9.67,0,17.5,6.35,17.5,14.17h0V93.17H3.85V17.23c0-7.82,7.83-14.17,17.5-14.17Z"
        transform="translate(0 -0.56)"
        fill="#fff"
        stroke={colorToUse}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <circle cx="72.47" cy="47.56" r="19.58" fill={colorToUse} />
      <circle cx="123.8" cy="47.22" r="19.58" fill={colorToUse} />
      <circle cx="175.37" cy="47.22" r="19.58" fill={colorToUse} />
      <path
        d="M303.94,282.15a118.17,118.17,0,1,1-2.14-22.56A119.84,119.84,0,0,1,303.94,282.15Z"
        transform="translate(0 -0.56)"
        fill="#fff"
      />
      <rect x="804.84" y="594.5" width="45.96" height="179.23" fill="#fff" />
      <rect x="738.21" y="531.7" width="45.96" height="242.04" fill="#fff" />
      <rect x="671.41" y="638.16" width="45.96" height="135.57" fill="#fff" />
      <line
        x1="443.1"
        y1="199.86"
        x2="385.62"
        y2="199.86"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="641.04"
        y1="199.86"
        x2="475.02"
        y2="199.86"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="613.9"
        y1="240.72"
        x2="447.88"
        y2="240.72"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="613.9"
        y1="281.59"
        x2="537.28"
        y2="281.59"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="551.64"
        y1="322.46"
        x2="385.62"
        y2="322.46"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="861.33"
        y1="323.82"
        x2="695.31"
        y2="323.82"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="415.96"
        y1="240.72"
        x2="385.62"
        y2="240.72"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="747.99"
        y1="240.72"
        x2="645.82"
        y2="240.72"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="505.35"
        y1="281.59"
        x2="385.62"
        y2="281.59"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="703.3"
        y1="199.86"
        x2="672.97"
        y2="199.86"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="663.39"
        y1="323.82"
        x2="583.57"
        y2="323.82"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="487.79"
        y1="363.32"
        x2="385.62"
        y2="363.32"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="115.85"
        y1="571.98"
        x2="85.51"
        y2="571.98"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="473.42"
        y1="571.98"
        x2="415.96"
        y2="571.98"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="249.94"
        y1="571.98"
        x2="147.77"
        y2="571.98"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="384.03"
        y1="571.98"
        x2="281.86"
        y2="571.98"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="187.68"
        y1="612.85"
        x2="85.51"
        y2="612.85"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="385.62"
        y1="612.85"
        x2="219.61"
        y2="612.85"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="447.88"
        y1="612.85"
        x2="417.55"
        y2="612.85"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="510.14"
        y1="612.85"
        x2="479.81"
        y2="612.85"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="251.53"
        y1="653.72"
        x2="85.51"
        y2="653.72"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="449.48"
        y1="653.72"
        x2="283.46"
        y2="653.72"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="583.57"
        y1="653.72"
        x2="481.4"
        y2="653.72"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="162.13"
        y1="694.58"
        x2="85.51"
        y2="694.58"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="226"
        y1="694.58"
        x2="195.67"
        y2="694.58"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="360.09"
        y1="694.58"
        x2="257.92"
        y2="694.58"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="422.34"
        y1="694.58"
        x2="392.01"
        y2="694.58"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="524.5"
        y1="735.45"
        x2="237.17"
        y2="735.45"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="205.24"
        y1="735.45"
        x2="85.51"
        y2="735.45"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <path
        d="M953.21,174.88h384.2c3.9,0,7.06,6.31,7.06,14.11V956.11c0,7.79-3.16,14.11-7.06,14.11H953.21c-3.9,0-7.06-6.32-7.06-14.11V189C946.15,181.19,949.31,174.88,953.21,174.88Z"
        transform="translate(0 -0.56)"
        fill={colorToUse}
      />
      <path
        d="M956.6,175.17h379c3.88,0,7,5.64,7,12.6h0V255.3h-393V187.77c0-7,3.14-12.6,7-12.6Z"
        transform="translate(0 -0.56)"
        fill="#fff"
        stroke={colorToUse}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <circle cx="1010.6" cy="214.67" r="17.41" fill={colorToUse} />
      <circle cx="1056.25" cy="214.38" r="17.41" fill={colorToUse} />
      <circle cx="1102.11" cy="214.38" r="17.41" fill={colorToUse} />
      <path
        d="M1203.69,364.19a63,63,0,1,1-1.15-12A63,63,0,0,1,1203.69,364.19Z"
        transform="translate(0 -0.56)"
        fill="#fff"
      />
      <rect x="1166.85" y="657.67" width="27.51" height="86.44" fill="#fff" />
      <rect x="1126.95" y="627.38" width="27.51" height="116.73" fill="#fff" />
      <rect x="1086.96" y="678.72" width="27.51" height="65.38" fill="#fff" />
      <line
        x1="1019.53"
        y1="472.16"
        x2="980.93"
        y2="472.16"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1152.46"
        y1="472.16"
        x2="1040.96"
        y2="472.16"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1134.23"
        y1="492.94"
        x2="1022.74"
        y2="492.94"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1134.23"
        y1="513.72"
        x2="1082.77"
        y2="513.72"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1092.42"
        y1="534.5"
        x2="980.93"
        y2="534.5"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1300.4"
        y1="535.19"
        x2="1188.9"
        y2="535.19"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1001.3"
        y1="492.94"
        x2="980.93"
        y2="492.94"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1224.28"
        y1="492.94"
        x2="1155.67"
        y2="492.94"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1061.33"
        y1="513.72"
        x2="980.93"
        y2="513.72"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1194.27"
        y1="472.16"
        x2="1173.9"
        y2="472.16"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1167.47"
        y1="535.19"
        x2="1113.86"
        y2="535.19"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1049.54"
        y1="555.28"
        x2="980.93"
        y2="555.28"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1002.08"
        y1="792.7"
        x2="982.17"
        y2="792.7"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1236.7"
        y1="792.7"
        x2="1199"
        y2="792.7"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1090.06"
        y1="792.7"
        x2="1023.02"
        y2="792.7"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1178.04"
        y1="792.7"
        x2="1111.01"
        y2="792.7"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1049.21"
        y1="818.14"
        x2="982.17"
        y2="818.14"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1179.09"
        y1="818.14"
        x2="1070.16"
        y2="818.14"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1219.94"
        y1="818.14"
        x2="1200.04"
        y2="818.14"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1260.79"
        y1="818.14"
        x2="1240.89"
        y2="818.14"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1091.11"
        y1="843.58"
        x2="982.17"
        y2="843.58"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1220.99"
        y1="843.58"
        x2="1112.06"
        y2="843.58"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1308.98"
        y1="843.58"
        x2="1241.94"
        y2="843.58"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1032.45"
        y1="869.01"
        x2="982.17"
        y2="869.01"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1074.35"
        y1="869.01"
        x2="1054.45"
        y2="869.01"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1162.33"
        y1="869.01"
        x2="1095.3"
        y2="869.01"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1203.18"
        y1="869.01"
        x2="1183.28"
        y2="869.01"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1270.22"
        y1="894.45"
        x2="1081.68"
        y2="894.45"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
      <line
        x1="1060.73"
        y1="894.45"
        x2="982.17"
        y2="894.45"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
    </svg>
  );
}

export default GreatUX;
